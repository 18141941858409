<script setup>
import { load } from 'recaptcha-v3'
import { reset } from '@formkit/core'
import { educationList } from '@/bucket/education-list.js'
import RightArrow from '@/assets/img/ico/right-arrow.svg'

const runtimeConfig = useRuntimeConfig()

const userData = ref(null)
const loading = ref(false)

const message = ref('')
const messageType = ref('') // 'success' / 'error'

const postinApiId = runtimeConfig.public.postinApiId

const onSubmit = async (formkitData, node) => {
  node.clearErrors()
  loading.value = true

  try {
    const recaptcha = await load(runtimeConfig.public.recaptchaKey)
    recaptcha.hideBadge()
    const token = await recaptcha.execute('postin')

    const formData = new FormData()
    formData.append('captcha', token)
    formData.append('api_id', postinApiId)
    formData.append('name', formkitData.name)
    formData.append('surname', formkitData.surname)
    formData.append('phone', formkitData.phone)
    formData.append('email', formkitData.email)
    formData.append('education', formkitData.education)
    formData.append('message', formkitData.message)
    formData.append('terms', formkitData.terms)

    const response = await fetch(runtimeConfig.public.postinUrl, {
      method: 'POST',
      body: formData,
    })

    loading.value = false

    if (response.status === 200) {
      message.value = 'Richiesta inviata con successo.'
      messageType.value = 'success'
      reset(node)
    } else {
      const errorText = await response.text()
      console.error('Error response:', errorText)
      message.value = 'Invio richiesta non riuscito.'
      messageType.value = 'error'
    }
  } catch (error) {
    loading.value = false
    console.error('Error:', error)
    message.value = 'Invio richiesta non riuscito.'
    messageType.value = 'error'
  }
}
</script>

<template>
  <div class="form">
    <!-- eslint-disable vue/no-v-html -->
    <FormKit
      id="requestForm"
      v-model="userData"
      type="form"
      :actions="false"
      @submit="onSubmit"
    >
      <div class="form-contact__grid">
        <!-- Nome -->
        <FormKit type="text" name="name" label="Nome" validation="required" />

        <!-- Cognome -->
        <FormKit
          type="text"
          name="surname"
          label="Cognome"
          validation="required"
        />

        <!-- Telephone -->
        <FormKit
          type="text"
          name="phone"
          label="Numero di telefono"
          validation="required|matches:/^[0-9]+$/"
        />

        <!-- Email -->
        <FormKit
          type="email"
          name="email"
          label="Indirizzo email"
          validation="required|email"
        />

        <!-- Ciclo Istruzione  -->
        <FormKit
          type="select"
          select-icon="down"
          label="Ciclo di istruzione"
          placeholder="---"
          name="education"
          :options="educationList"
          validation="required"
        />

        <!-- Messaggio -->
        <FormKit
          type="textarea"
          name="message"
          label="Messaggio"
          rows="7"
          validation="required"
        />
      </div>
      <!-- CHECKBOX -->
      <FormKit
        type="checkbox"
        name="terms"
        validation="accepted"
        :validation-messages="{ accepted: $t('form.termsError') }"
      >
        <template #label>
          <div class="privacy">
            {{ $t('form.termsExtLabelPart1')
            }}<a
              href="https://www.iubenda.com/privacy-policy/39740081"
              target="_blank"
              class="link-underline-1"
            >
              {{ $t('form.termsLabelLinkText') }} </a
            >,
            {{ $t('form.termsExtLabelPart2') }}
          </div>
        </template>
      </FormKit>
      <div class="form-contact__cta">
        <button
          type="submit"
          class="form-contact__submit button-round-1 is-orange"
        >
          <span>Invia richiesta</span>
          <RightArrow class="arrow-icon" :filled="true" />
        </button>
      </div>
      <div v-if="message" :class="[messageType]" class="result-message text-2">
        {{ message }}
      </div>
    </FormKit>
  </div>
</template>

<script>
export default {
  name: 'FormContact',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
